define("@dwind/widi-uikit/components/widi-tabs", ["exports", "@dwind/widi-uikit/components/widi-parent-component"], function (_exports, _widiParentComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _widiParentComponent.default.extend({
    classNames: 'widi-tabs',
    classNameBindings: ['tabOpen:open'],
    tabComponent: 'widi-tabs-tab',
    tabOpen: false,
    register: function register(child) {
      if (!this.childComponents.length) {
        Ember.set(child, 'active', true);
      }

      this._super.apply(this, arguments);
    },
    _openTab: function _openTab(tab) {
      this.childComponents.setEach('active', false);

      if (!Ember.isEmpty(tab)) {
        Ember.set(tab, 'active', true);
      }

      if (this.onChange) {
        this.onChange(tab);
      }
    },
    actions: {
      openTab: function openTab(tab) {
        return this._openTab(tab);
      },
      togglePanel: function togglePanel() {
        this.toggleProperty('tabOpen');
      }
    }
  });

  _exports.default = _default;
});