define("@dwind/widi-uikit/components/widi-card", ["exports", "@dwind/widi-uikit/components/widi-base-component"], function (_exports, _widiBaseComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _widiBaseComponent.default.extend({
    classNames: ['widi-card'],
    classNameBindings: ['background:widi-card-background']
  });

  _exports.default = _default;
});