define("@dwind/widi-uikit/components/widi-radio", ["exports", "@dwind/widi-uikit/components/widi-base-component"], function (_exports, _widiBaseComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _widiBaseComponent.default.extend({
    componentName: 'widi-radio',
    labelComponent: 'widi-label',
    componentId: Ember.computed('name', 'value', {
      get: function get() {
        return "".concat(this.name, "-").concat((this.value + '').replace(/\s/gi, '-'));
      }
    }).readOnly(),
    checked: Ember.computed('groupValue', 'value', {
      get: function get() {
        return this.groupValue !== undefined && this.groupValue === this.value;
      }
    }).readOnly(),
    actions: {
      onClick: function onClick(event) {
        if (this.onChange && event.target.checked != this.checked) {
          this.onChange(this.checked ? null : this.value);
        }
      }
    }
  });

  _exports.default = _default;
});