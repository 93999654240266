define("@dwind/widi-uikit/components/widi-accordion", ["exports", "@dwind/widi-uikit/components/widi-parent-component"], function (_exports, _widiParentComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _widiParentComponent.default.extend({
    componentName: 'widi-accordion',
    elementComponent: 'widi-accordion-element',
    unexpandAll: function unexpandAll() {
      this.childComponents.setEach('expanded', false);
    }
  });

  _exports.default = _default;
});