define("@dwind/widi-uikit/templates/components/widi-lp-advantages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UaYb1Ol9",
    "block": "{\"symbols\":[\"plus\",\"@advantages\"],\"statements\":[[4,\"if\",[[22,2,[]]],null,{\"statements\":[[0,\"  \"],[7,\"section\"],[11,\"class\",\"widi-advantages\"],[9],[0,\"\\n    \"],[7,\"h2\"],[11,\"class\",\"widi-advantages__title\"],[9],[1,[27,\"t\",[\"widi-uikit.advantages.wind_why_convenient\"],null],false],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"widi-advantages__container\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"advantages\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"widi-advantages__advantage\"],[9],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"widi-advantages__advantage__titlebox\"],[9],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"widi-advantages__advantage__icon\"],[9],[0,\"\\n              \"],[7,\"img\"],[12,\"src\",[22,1,[\"icon\"]]],[12,\"alt\",[22,1,[\"title\"]]],[9],[10],[0,\"\\n            \"],[10],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"widi-advantages__advantage__title\"],[9],[1,[22,1,[\"title\"]],false],[10],[0,\"\\n          \"],[10],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"widi-advantages__advantage__description\"],[9],[0,\"\\n\"],[0,\"            \"],[1,[22,1,[\"description\"]],true],[0,\"\\n          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@dwind/widi-uikit/templates/components/widi-lp-advantages.hbs"
    }
  });

  _exports.default = _default;
});