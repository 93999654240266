define("@dwind/widi-uikit/components/widi-accordion-element", ["exports", "@dwind/widi-uikit/components/widi-child-component"], function (_exports, _widiChildComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _widiChildComponent.default.extend({
    componentName: 'widi-accordion-element',
    expanded: false,
    click: function click() {
      var expanded = this.expanded;
      this.parentComponent.unexpandAll();
      Ember.set(this, 'expanded', !expanded);

      if (this.onExpand) {
        this.onExpand();
      }
    }
  });

  _exports.default = _default;
});