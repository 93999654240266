define("@dwind/widi-uikit/components/widi-lp-flash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    actions: {
      onSelectedItemClick: function onSelectedItemClick(args) {
        if (this.onItemClick) {
          this.onItemClick(args);
        }
      }
    }
  });

  _exports.default = _default;
});