define("ember-animated/templates/components/animated-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Q0tBzS8v",
    "block": "{\"symbols\":[\"@class\",\"&attrs\",\"&default\"],\"statements\":[[8,\"div\"],[12,\"class\",[28,[\"animated-container \",[22,1,[]]]]],[13,2],[9],[0,\"\\n  \"],[14,3],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-animated/templates/components/animated-container.hbs"
    }
  });

  _exports.default = _default;
});