define("@dwind/widi-uikit/templates/components/widi-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "p/wgKr2q",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[27,\"hash\",null,[[\"isValid\",\"autocomplete\",\"button\",\"checkbox\",\"input\",\"radioGroup\",\"submit\"],[[27,\"readonly\",[[23,[\"isValid\"]]],null],[27,\"component\",[[23,[\"autocompleteComponent\"]]],[[\"parentComponent\"],[[22,0,[]]]]],[27,\"component\",[[23,[\"buttonComponent\"]]],[[\"parentComponent\"],[[22,0,[]]]]],[27,\"component\",[[23,[\"checkboxComponent\"]]],[[\"parentComponent\"],[[22,0,[]]]]],[27,\"component\",[[23,[\"inputComponent\"]]],[[\"parentComponent\"],[[22,0,[]]]]],[27,\"component\",[[23,[\"radioGroupComponent\"]]],[[\"parentComponent\"],[[22,0,[]]]]],[27,\"component\",[[23,[\"submitComponent\"]]],null]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@dwind/widi-uikit/templates/components/widi-form.hbs"
    }
  });

  _exports.default = _default;
});