define("@dwind/widi-uikit/templates/components/widi-lp-features", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6c5D5OV0",
    "block": "{\"symbols\":[\"plus\",\"@features\"],\"statements\":[[4,\"if\",[[22,2,[]]],null,{\"statements\":[[0,\"  \"],[7,\"section\"],[11,\"class\",\"widi-features\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"widi-features__title\"],[9],[0,\"\\n      \"],[1,[27,\"t\",[\"widi-uikit.features.wind_advantages\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"widi-features__feature-list\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"features\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"widi-features__feature\"],[9],[0,\"\\n          \"],[7,\"figure\"],[9],[0,\"\\n            \"],[7,\"img\"],[12,\"src\",[22,1,[\"icon\"]]],[12,\"alt\",[22,1,[\"title\"]]],[9],[10],[0,\"\\n          \"],[10],[0,\"\\n          \"],[7,\"div\"],[9],[0,\"\\n            \"],[7,\"b\"],[11,\"class\",\"widi-features__feature__title\"],[9],[0,\"\\n              \"],[1,[22,1,[\"title\"]],false],[0,\"\\n            \"],[10],[0,\"\\n            \"],[7,\"p\"],[11,\"class\",\"widi-features__feature__description\"],[9],[0,\"\\n\"],[0,\"              \"],[1,[22,1,[\"description\"]],true],[0,\"\\n            \"],[10],[0,\"\\n          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@dwind/widi-uikit/templates/components/widi-lp-features.hbs"
    }
  });

  _exports.default = _default;
});