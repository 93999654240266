define("@dwind/widi-uikit/templates/components/widi-lp-flash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6wKQDkZo",
    "block": "{\"symbols\":[\"tabs\",\"detailedInfo\",\"tab\",\"@infos\"],\"statements\":[[4,\"if\",[[22,4,[]]],null,{\"statements\":[[0,\"  \"],[7,\"section\"],[11,\"class\",\"widi-flash\"],[9],[0,\"\\n    \"],[5,\"widi-tabs\",[],[[\"@onChange\"],[[27,\"action\",[[22,0,[]],\"onSelectedItemClick\"],null]]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[23,[\"infos\"]]],null,{\"statements\":[[0,\"        \"],[6,[22,1,[\"tab\"]],[],[[\"@title\"],[[22,2,[\"title\"]]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[22,3,[\"active\"]]],null,{\"statements\":[[0,\"            \"],[1,[22,2,[\"description\"]],true],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"]],\"parameters\":[3]}],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"    \"]],\"parameters\":[1]}],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@dwind/widi-uikit/templates/components/widi-lp-flash.hbs"
    }
  });

  _exports.default = _default;
});