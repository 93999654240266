define('ember-data-model-fragments/ext', ['exports', 'ember-data/store', 'ember-data/model', 'ember-data/-private', 'ember-data/serializers/json', 'ember-data-model-fragments/states', 'ember-data-model-fragments/fragment', 'ember-data-model-fragments/array/fragment'], function (exports, _store, _model, _private, _json, _states, _fragment, _fragment2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.JSONSerializer = exports.Model = exports.Store = undefined;


  function serializerForFragment(owner, normalizedModelName) {
    var serializer = owner.lookup('serializer:' + normalizedModelName);

    if (serializer !== undefined) {
      return serializer;
    }

    // no serializer found for the specific model, fallback and check for application serializer
    serializer = owner.lookup('serializer:-fragment');
    if (serializer !== undefined) {
      return serializer;
    }

    // final fallback, no model specific serializer, no application serializer, no
    // `serializer` property on store: use json-api serializer
    serializer = owner.lookup('serializer:-default');

    return serializer;
  }
  /**
    @module ember-data-model-fragments
  */

  var InternalModelPrototype = _private.InternalModel.prototype;
  var RecordDataPrototype = _private.RecordData.prototype;

  Object.assign(RecordDataPrototype, {
    eachFragmentKey: function eachFragmentKey(fn) {
      this._fragments = this._fragments || Object.create({});
      Object.keys(this._fragments).forEach(fn);
    },
    eachFragmentKeyValue: function eachFragmentKeyValue(fn) {
      var _this = this;

      this.eachFragmentKey(function (key) {
        var value = _this.getFragment(key);
        if (value) {
          fn(key, value);
        }
      });
    },
    getOwner: function getOwner() {
      return this._owner;
    },
    setOwner: function setOwner(value) {
      this._owner = value;
    },
    setName: function setName(value) {
      this._name = value;
    },
    getName: function getName() {
      return this._name;
    },
    getFragment: function getFragment(name) {
      this._fragments = this._fragments || Object.create({});
      return this._fragments[name];
    },
    setFragment: function setFragment(name, fragment) {
      this._fragments = this._fragments || Object.create({});
      this._fragments[name] = fragment;
      return this._fragments[name];
    },
    didCommit: function didCommit(data) {
      this._isNew = false;
      if (data) {
        if (data.relationships) {
          this._setupRelationships(data);
        }
        if (data.id) {
          // didCommit provided an ID, notify the store of it
          this.storeWrapper.setRecordId(this.modelName, data.id, this.clientId);
          this.id = (0, _private.coerceId)(data.id);
        }
        data = data.attributes;

        // Notify fragments that the record was committed
        this.eachFragmentKeyValue(function (key, fragment) {
          return fragment._adapterDidCommit(data[key]);
        });
      } else {
        this.eachFragmentKeyValue(function (key, fragment) {
          return fragment._adapterDidCommit();
        });
      }

      var changedKeys = this._changedKeys(data);

      Object.assign(this._data, this.__inFlightAttributes, this._attributes, data);
      this._attributes = null;
      this._inFlightAttributes = null;
      this._updateChangedAttributes();

      return changedKeys;
    }
  });

  /**
    @class Store
    @namespace DS
  */
  _store.default.reopen({
    createFragment: function createFragment(modelName, props) {
      (false && !(this.isFragment(modelName)) && Ember.assert('The \'' + modelName + '\' model must be a subclass of MF.Fragment', this.isFragment(modelName)));


      var internalModel = new _private.InternalModel(modelName, null, this, Ember.getOwner(this).container);

      // Re-wire the internal model to use the fragment state machine
      internalModel.currentState = _states.default.empty;

      internalModel._recordData._name = null;
      internalModel._recordData.setOwner(null);

      internalModel.loadedData();

      var fragment = internalModel.getRecord();

      if (props) {
        fragment.setProperties(props);
      }

      // invoke the ready callback ( to mimic DS.Model behaviour )
      fragment.trigger('ready');

      // Add brand to reduce usages of `instanceof`
      fragment._isFragment = true;

      return fragment;
    },
    isFragment: function isFragment(modelName) {
      if (modelName === 'application' || modelName === '-default') {
        return false;
      }

      var type = this.modelFor(modelName);
      return _fragment.default.detect(type);
    },
    serializerFor: function serializerFor(modelName) {
      (false && !(Ember.isPresent(modelName)) && Ember.assert('You need to pass a model name to the store\'s serializerFor method', Ember.isPresent(modelName)));
      (false && !(typeof modelName === 'string') && Ember.assert('Passing classes to store.serializerFor has been removed. Please pass a dasherized string instead of ' + modelName, typeof modelName === 'string'));


      var owner = Ember.getOwner(this);
      var normalizedModelName = (0, _private.normalizeModelName)(modelName);

      if (this.isFragment(normalizedModelName)) {
        return serializerForFragment(owner, normalizedModelName);
      } else {
        return this._super.apply(this, arguments);
      }
    }
  });

  /**
    @class Model
    @namespace DS
    */
  _model.default.reopen({
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      var internalModel = (0, _fragment.internalModelFor)(this);
      var key = void 0,
          fragment = void 0;

      // destroy the current state
      for (key in internalModel._recordData._fragments) {
        fragment = internalModel._recordData._fragments[key];
        if (fragment) {
          fragment.destroy();
          delete internalModel._recordData._fragments[key];
        }
      }

      // destroy the original state
      for (key in internalModel._recordData._data) {
        fragment = internalModel._recordData._data[key];
        if (fragment instanceof _fragment.default || fragment instanceof _fragment2.default) {
          fragment.destroy();
          delete internalModel._recordData._data[key];
        }
      }
    }
  });

  _model.default.reopenClass({
    fields: Ember.computed(function () {
      var map = new Map();

      this.eachComputedProperty(function (name, meta) {
        if (meta.isFragment) {
          map.set(name, 'fragment');
        } else if (meta.isRelationship) {
          map.set(name, meta.kind);
        } else if (meta.isAttribute) {
          map.set(name, 'attribute');
        }
      });

      return map;
    }).readOnly()

  });

  // Replace a method on an object with a new one that calls the original and then
  // invokes a function with the result
  function decorateMethod(obj, name, fn) {
    var originalFn = obj[name];

    obj[name] = function () {
      var value = originalFn.apply(this, arguments);

      return fn.call(this, value, arguments);
    };
  }

  function decorateMethodBefore(obj, name, fn) {
    var originalFn = obj[name];
    obj[name] = function () {
      fn.apply(this, arguments);
      return originalFn.apply(this, arguments);
    };
  }

  /**
    Override parent method to snapshot fragment attributes before they are
    passed to the `DS.Model#serialize`.
  
    @method _createSnapshot
    @private
  */
  decorateMethod(InternalModelPrototype, 'createSnapshot', function createFragmentSnapshot(snapshot) {
    var attrs = snapshot._attributes;
    Object.keys(attrs).forEach(function (key) {
      var attr = attrs[key];
      // If the attribute has a `_createSnapshot` method, invoke it before the
      // snapshot gets passed to the serializer
      if (attr && typeof attr._createSnapshot === 'function') {
        attrs[key] = attr._createSnapshot();
      }
    });

    return snapshot;
  });

  decorateMethod(InternalModelPrototype, 'adapterDidError', function adapterDidErrorFragments(returnValue, args) {
    var error = args[0] || Object.create(null);
    this._recordData.eachFragmentKeyValue(function (key, value) {
      value._adapterDidError(error);
    });
  });

  decorateMethod(InternalModelPrototype, 'rollbackAttributes', function rollbackFragments() {
    this._recordData.eachFragmentKeyValue(function (key, value) {
      value.rollbackAttributes();
    });
  });

  decorateMethod(RecordDataPrototype, 'changedAttributes', function changedAttributes(diffData) {
    var _this2 = this;

    this.eachFragmentKey(function (name) {
      if (name in _this2._attributes) {
        diffData[name] = [diffData[name][0], diffData[name][1] ? diffData[name][1]._record : diffData[name][1]];
      }
    });
    return diffData;
  });

  decorateMethodBefore(RecordDataPrototype, 'willCommit', function willCommit() {
    this.eachFragmentKeyValue(function (key, fragment) {
      return fragment._flushChangedAttributes();
    });
  });

  decorateMethodBefore(RecordDataPrototype, 'commitWasRejected', function commitWasRejected() {
    this.eachFragmentKeyValue(function (key, fragment) {
      return fragment._adapterDidError();
    });
  });

  /**
    @class JSONSerializer
    @namespace DS
  */
  _json.default.reopen({
    transformFor: function transformFor(attributeType) {
      if (attributeType.indexOf('-mf-') !== 0) {
        return this._super.apply(this, arguments);
      }

      var owner = Ember.getOwner(this);
      var containerKey = 'transform:' + attributeType;

      if (!owner.hasRegistration(containerKey)) {
        var match = attributeType.match(/^-mf-(fragment|fragment-array|array)(?:\$([^$]+))?(?:\$(.+))?$/);
        var transformName = match[1];
        var type = match[2];
        var polymorphicTypeProp = match[3];
        var transformClass = owner.factoryFor('transform:' + transformName);
        transformClass = transformClass && transformClass.class;
        transformClass = transformClass.extend({
          type: type,
          polymorphicTypeProp: polymorphicTypeProp,
          store: this.store
        });
        owner.register(containerKey, transformClass);
      }
      return owner.lookup(containerKey);
    }
  });

  exports.Store = _store.default;
  exports.Model = _model.default;
  exports.JSONSerializer = _json.default;
});