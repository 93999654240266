define("@dwind/widi-uikit/components/widi-checkbox", ["exports", "@dwind/widi-uikit/components/widi-child-component"], function (_exports, _widiChildComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _widiChildComponent.default.extend({
    componentName: 'widi-checkbox',
    labelComponent: 'widi-label',
    attributeBindings: ['checked:checked'],
    actions: {
      onClick: function onClick() {
        var checked = !this.checked;
        Ember.set(this, 'checked', checked);

        if (this.onChange) {
          this.onChange(checked);
        }
      }
    }
  });

  _exports.default = _default;
});