define("@dwind/widi-uikit/templates/components/widi-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "azSbBj2H",
    "block": "{\"symbols\":[\"&default\",\"@disabled\"],\"statements\":[[4,\"if\",[[24,1]],null,{\"statements\":[[0,\"  \"],[14,1,[[27,\"hash\",null,[[\"label\"],[[27,\"component\",[[23,[\"labelComponent\"]]],[[\"for\"],[[23,[\"componentId\"]]]]]]]]]],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"input\"],[12,\"id\",[21,\"componentId\"]],[12,\"disabled\",[22,2,[]]],[12,\"value\",[27,\"readonly\",[[23,[\"value\"]]],null]],[12,\"maxlength\",[21,\"maxlength\"]],[12,\"autocomplete\",[21,\"autocomplete\"]],[12,\"placeholder\",[21,\"placeholder\"]],[12,\"onkeypress\",[27,\"action\",[[22,0,[]],\"onKeyPress\"],null]],[12,\"oninput\",[27,\"action\",[[22,0,[]],\"onChange\"],[[\"value\"],[\"target.value\"]]]],[12,\"type\",[21,\"inputType\"]],[9],[10],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@dwind/widi-uikit/templates/components/widi-input.hbs"
    }
  });

  _exports.default = _default;
});