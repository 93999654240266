define("@dwind/widi-uikit/components/widi-radio-group", ["exports", "@dwind/widi-uikit/components/widi-child-component"], function (_exports, _widiChildComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _widiChildComponent.default.extend({
    componentName: 'widi-radio-group',
    classNameBindings: ['inline'],
    itemComponent: 'widi-radio',
    actions: {
      onChange: function onChange(value) {
        if (this.onChange) {
          this.onChange(value);
        }
      }
    }
  });

  _exports.default = _default;
});