define("@dwind/widi-uikit/templates/components/widi-otp", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Mzx/GUQ7",
    "block": "{\"symbols\":[],\"statements\":[[0,\" \"],[1,[27,\"paper-input-mask\",null,[[\"mask\",\"label\",\"value\",\"pattern\",\"onChange\",\"passThru\"],[[23,[\"mask\"]],[23,[\"label\"]],[23,[\"value\"]],[23,[\"pattern\"]],[27,\"action\",[[22,0,[]],\"onChange\"],null],[27,\"hash\",null,[[\"pattern\"],[[23,[\"pattern\"]]]]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@dwind/widi-uikit/templates/components/widi-otp.hbs"
    }
  });

  _exports.default = _default;
});