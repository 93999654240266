define("@dwind/widi-uikit/components/widi-input", ["exports", "@dwind/widi-uikit/components/widi-child-component"], function (_exports, _widiChildComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _widiChildComponent.default.extend({
    componentName: 'widi-input',
    labelComponent: 'widi-label',
    type: 'text',
    inputType: 'text',
    actions: {
      onKeyPress: function onKeyPress(event) {
        var key = event.keyCode || event.which;

        if (this.type === 'number' && (key < 47 || key > 58)) {
          event.stopPropagation();
          event.preventDefault();
        }
      },
      onChange: function onChange(value) {
        if (this.onChange) {
          this.onChange(value);
        }
      }
    }
  });

  _exports.default = _default;
});