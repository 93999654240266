define("@dwind/widi-uikit/templates/components/widi-lp-additional-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hp3NmevW",
    "block": "{\"symbols\":[\"accordion\",\"info\",\"element\",\"@infos\"],\"statements\":[[4,\"if\",[[22,4,[]]],null,{\"statements\":[[0,\"  \"],[7,\"section\"],[11,\"class\",\"widi-additional-info\"],[9],[0,\"\\n    \"],[5,\"widi-accordion\",[],[[\"@classNames\",\"@tagName\"],[\"widi-additional-info-terms\",\"section\"]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[23,[\"infos\"]]],null,{\"statements\":[[0,\"        \"],[6,[22,1,[\"element\"]],[],[[\"@onExpand\",\"@classNames\"],[[27,\"action\",[[22,0,[]],\"onAccordionItemClick\",[22,2,[\"title\"]]],null],\"widi-additional-info-terms-item\"]],{\"statements\":[[0,\"\\n          \"],[7,\"div\"],[12,\"class\",[28,[\"widi-additional-info-terms-item__titlebox \",[27,\"if\",[[22,3,[\"expanded\"]],\"widi-additional-info-terms-item--open\"],null]]]],[9],[0,\"\\n            \"],[7,\"b\"],[11,\"class\",\"widi-additional-info-terms-item__title\"],[9],[1,[22,2,[\"title\"]],false],[10],[0,\"\\n            \"],[7,\"span\"],[11,\"class\",\"widi-additional-info-terms-item__trigger\"],[9],[1,[27,\"if\",[[22,3,[\"expanded\"]],\"-\",\"+\"],null],false],[10],[0,\"\\n          \"],[10],[0,\"\\n\"],[4,\"if\",[[22,3,[\"expanded\"]]],null,{\"statements\":[[0,\"            \"],[7,\"div\"],[11,\"class\",\"widi-additional-info-terms-item__content\"],[9],[0,\"\\n\"],[0,\"              \"],[1,[22,2,[\"description\"]],true],[0,\"\\n            \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"]],\"parameters\":[3]}],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"    \"]],\"parameters\":[1]}],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@dwind/widi-uikit/templates/components/widi-lp-additional-info.hbs"
    }
  });

  _exports.default = _default;
});