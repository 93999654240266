define("@dwind/widi-uikit/templates/components/widi-label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CwIFrMKM",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@dwind/widi-uikit/templates/components/widi-label.hbs"
    }
  });

  _exports.default = _default;
});