define("@dwind/widi-uikit/components/widi-countdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
    init: function init() {
      this._super.apply(this, arguments);

      this.update(true);
    },
    update: function update() {
      var _this = this;

      var first = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

      if (!(this.isDestroyed || this.isDestroying)) {
        var now = new Date();
        var days = 0;
        var hours = 0;
        var minutes = 0;
        var seconds = 0;
        var delta = (this.ends - now) / 1000;

        if (delta > 0) {
          days = Math.floor(delta / 86400);
          delta -= days * 86400;
          hours = Math.floor(delta / 3600) % 24;
          delta -= hours * 3600;
          minutes = Math.floor(delta / 60) % 60;
          delta -= minutes * 60;
          seconds = Math.floor(delta) % 60;
        } else {
          if (!Ember.get(this, 'alreadyFinished')) {
            Ember.set(this, 'alreadyFinished', true);

            var finishCallback = Ember.get(this, 'onCountdownEnd') || function () {};

            finishCallback();
          }
        }

        Ember.setProperties(this, {
          days: days,
          hours: hours,
          minutes: minutes,
          seconds: seconds
        });
        var onTick = Ember.get(this, 'onTick');

        if (onTick) {
          onTick({
            days: days,
            hours: hours,
            minutes: minutes,
            seconds: seconds,
            first: first
          });
        }

        Ember.run.later(function () {
          _this.update();
        }, 1000);
      }
    }
  });

  _exports.default = _default;
});