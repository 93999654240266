define("@dwind/widi-uikit/components/widi-submit", ["exports", "@dwind/widi-uikit/components/widi-button"], function (_exports, _widiButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _widiButton.default.extend({
    componentName: 'widi-submit',
    type: 'submit',
    click: function click(event) {
      event.preventDefault();

      if (this.onClick) {
        this.onClick();
      }
    }
  });

  _exports.default = _default;
});