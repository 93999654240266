define("@dwind/widi-uikit/components/widi-hero", ["exports", "@dwind/widi-uikit/components/widi-base-component"], function (_exports, _widiBaseComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _widiBaseComponent.default.extend({
    tagName: 'figure',
    classNames: ['widi-hero'],
    classNameBindings: ['hasBackground:widi-hero-with-background'],
    backgroundComponent: 'widi-hero-background',
    contentComponent: 'widi-hero-content'
  });

  _exports.default = _default;
});