define("@dwind/widi-uikit/templates/components/widi-autocomplete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YIRdyJeB",
    "block": "{\"symbols\":[\"selectedItem\",\"&default\",\"@searchMessage\",\"@disabled\"],\"statements\":[[4,\"if\",[[24,2]],null,{\"statements\":[[0,\"  \"],[14,2,[[27,\"hash\",null,[[\"label\"],[[27,\"component\",[[23,[\"labelComponent\"]]],[[\"for\"],[[23,[\"componentId\"]]]]]]]]]],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"power-select-typeahead\",null,[[\"id\",\"search\",\"selected\",\"disabled\",\"searchMessage\",\"oninput\",\"onchange\",\"extra\"],[[23,[\"componentId\"]],[27,\"action\",[[22,0,[]],\"search\"],null],[23,[\"value\"]],[22,4,[]],[27,\"if\",[[22,3,[]],[22,3,[]],\"Comincia a scrivere...\"],null],[27,\"action\",[[22,0,[]],\"onSearchInput\"],null],[27,\"action\",[[22,0,[]],\"onSelect\"],null],[27,\"hash\",null,[[\"labelPath\"],[\"value\"]]]]],{\"statements\":[[0,\"  \"],[1,[22,1,[\"value\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@dwind/widi-uikit/templates/components/widi-autocomplete.hbs"
    }
  });

  _exports.default = _default;
});