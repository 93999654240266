define("@dwind/widi-uikit/templates/components/widi-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "S77UsSvn",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"widi-modal-container\"],[9],[0,\"\\n\"],[0,\"  \"],[7,\"div\"],[11,\"role\",\"button\"],[11,\"class\",\"close\"],[9],[0,\"×\"],[3,\"action\",[[22,0,[]],\"closeModal\"]],[10],[0,\"\\n  \"],[14,1,[[27,\"hash\",null,[[\"content\",\"footer\",\"header\"],[[27,\"component\",[[23,[\"contentComponent\"]]],null],[27,\"component\",[[23,[\"footerComponent\"]]],null],[27,\"component\",[[23,[\"headerComponent\"]]],null]]]]]],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@dwind/widi-uikit/templates/components/widi-modal.hbs"
    }
  });

  _exports.default = _default;
});