define("@dwind/widi-uikit/templates/components/widi-hero", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZyHvLAeA",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[27,\"hash\",null,[[\"content\",\"background\"],[[27,\"component\",[[23,[\"contentComponent\"]]],[[\"parentComponent\"],[[22,0,[]]]]],[27,\"component\",[[23,[\"backgroundComponent\"]]],[[\"parentComponent\"],[[22,0,[]]]]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@dwind/widi-uikit/templates/components/widi-hero.hbs"
    }
  });

  _exports.default = _default;
});