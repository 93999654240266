define("@dwind/widi-uikit/templates/components/widi-price", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "L84y7jfP",
    "block": "{\"symbols\":[\"@currency\",\"&default\"],\"statements\":[[4,\"if\",[[24,2]],null,{\"statements\":[[0,\"  \"],[14,2,[[27,\"hash\",null,[[\"amount\",\"currency\",\"units\",\"cents\",\"separator\"],[[23,[\"formattedAmount\"]],[23,[\"formattedCurrency\"]],[23,[\"units\"]],[23,[\"cents\"]],[23,[\"separator\"]]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[27,\"format-number\",[[23,[\"cleanAmount\"]]],[[\"style\",\"currency\"],[\"currency\",[22,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@dwind/widi-uikit/templates/components/widi-price.hbs"
    }
  });

  _exports.default = _default;
});