define("@dwind/widi-uikit/templates/components/widi-lp-campaigns", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0gLwqdy+",
    "block": "{\"symbols\":[\"campaign\",\"@campaigns\"],\"statements\":[[4,\"if\",[[22,2,[]]],null,{\"statements\":[[0,\"  \"],[7,\"section\"],[11,\"class\",\"widi-campaigns\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"campaigns\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"widi-campaigns__campaign\"],[9],[0,\"\\n\"],[4,\"if\",[[22,1,[\"isValid\"]]],null,{\"statements\":[[4,\"if\",[[22,1,[\"logo\"]]],null,{\"statements\":[[0,\"            \"],[7,\"img\"],[12,\"src\",[22,1,[\"logo\"]]],[12,\"alt\",[22,1,[\"name\"]]],[9],[10],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"widi-campaigns__text\"],[9],[0,\"\\n\"],[0,\"              \"],[1,[22,1,[\"description\"]],true],[0,\"\\n            \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"      \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@dwind/widi-uikit/templates/components/widi-lp-campaigns.hbs"
    }
  });

  _exports.default = _default;
});