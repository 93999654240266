define("@dwind/widi-uikit/components/widi-base-component", ["exports", "ember-in-viewport"], function (_exports, _emberInViewport) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_emberInViewport.default, {
    classNames: ['widi-component'],
    classNameBindings: ['componentName', 'required:widi-required', 'active:widi-active', 'focused:widi-focused', 'disabled:widi-disabled'],
    required: false,
    optional: Ember.computed.not('required'),
    disabled: false,
    enabled: Ember.computed.not('disabled'),
    active: Ember.computed('focused', 'value', {
      get: function get() {
        return this.focused || !Ember.isEmpty(this.value) || this.startActive;
      }
    }).readOnly(),
    componentId: Ember.computed('name', {
      get: function get() {
        return (this.name || '').replace(/\s/gi, '-');
      }
    }).readOnly(),
    focusIn: function focusIn() {
      Ember.set(this, 'focused', true);
    },
    focusOut: function focusOut() {
      Ember.set(this, 'focused', false);
    },
    init: function init() {
      this._super.apply(this, arguments);

      Ember.setProperties(this, {
        viewportEnabled: this.enableViewport,
        viewportSpy: true,
        viewportScrollSensitivity: 0.5,
        scrollableArea: null,
        viewportTolerance: {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0
        }
      });
    }
  });

  _exports.default = _default;
});