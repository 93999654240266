define("@dwind/widi-uikit/components/widi-child-component", ["exports", "@dwind/widi-uikit/components/widi-base-component"], function (_exports, _widiBaseComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _widiBaseComponent.default.extend({
    init: function init() {
      this._super.apply(this, arguments);

      if (this.parentComponent) {
        this.parentComponent.register(this);
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      if (this.parentComponent) {
        this.parentComponent.unregister(this);
      }
    }
  });

  _exports.default = _default;
});