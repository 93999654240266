define("@dwind/widi-uikit/components/widi-otp", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      onChange: function onChange(value) {
        this.onChange(value);
      }
    }
  });

  _exports.default = _default;
});