define("@dwind/widi-uikit/templates/components/widi-tabs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sBoUj+58",
    "block": "{\"symbols\":[\"tab\",\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"widi-tabs-header\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"widi-tabs-opacity left\"],[9],[0,\"\\n\"],[0,\"     \\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"widi-tabs-header-content\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"widi-tabs-list\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"childComponents\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[12,\"class\",[28,[\"widi-tabs-list-item \",[27,\"if\",[[22,1,[\"active\"]],\"active\"],null]]]],[11,\"role\",\"button\"],[9],[0,\"\\n          \"],[1,[22,1,[\"title\"]],false],[0,\"\\n        \"],[3,\"action\",[[22,0,[]],\"openTab\",[22,1,[]]]],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"widi-tabs-opacity right\"],[9],[0,\"\\n\"],[0,\"     \\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"widi-tabs-content\"],[9],[0,\"\\n  \"],[14,2,[[27,\"hash\",null,[[\"tab\"],[[27,\"component\",[[23,[\"tabComponent\"]]],[[\"parentComponent\"],[[22,0,[]]]]]]]]]],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"widi-tabs-content-opacity\"],[9],[0,\"\\n\"],[0,\"     \\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"widi-tabs-footer\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"role\",\"button\"],[11,\"class\",\"widi-tabs-footer-toggle\"],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"widi-uikit.tabs.toggle.show\"],null],false],[0,\" \"],[1,[27,\"if\",[[23,[\"tabOpen\"]],\"meno\",\"più\"],null],false],[0,\"\\n  \"],[3,\"action\",[[22,0,[]],\"togglePanel\"]],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@dwind/widi-uikit/templates/components/widi-tabs.hbs"
    }
  });

  _exports.default = _default;
});