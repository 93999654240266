define("@dwind/widi-uikit/components/widi-lp-additional-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    actions: {
      onAccordionItemClick: function onAccordionItemClick() {
        if (this.onItemClick) {
          this.onItemClick();
        }
      }
    }
  });

  _exports.default = _default;
});