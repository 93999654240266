define("@dwind/widi-uikit/components/widi-button", ["exports", "@dwind/widi-uikit/components/widi-child-component"], function (_exports, _widiChildComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _widiChildComponent.default.extend({
    componentName: 'widi-button',
    tagName: 'button',
    type: 'button',
    attributeBindings: ['type', 'componentId:id'],
    click: function click() {
      if (this.onClick) {
        this.onClick();
      }
    }
  });

  _exports.default = _default;
});