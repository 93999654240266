define("@dwind/widi-uikit/components/widi-hero-background", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'picture',
    classNames: ['widi-hero-background'],
    didRender: function didRender() {
      this._super.apply(this, arguments);

      Ember.set(this, 'parentComponent.hasBackground', true);
    }
  });

  _exports.default = _default;
});