define("@dwind/widi-uikit/components/widi-autocomplete", ["exports", "@dwind/widi-uikit/components/widi-child-component"], function (_exports, _widiChildComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _widiChildComponent.default.extend({
    labelComponent: 'widi-label',
    componentName: 'widi-autocomplete',
    active: Ember.computed('focused', 'value', 'searchText', {
      get: function get() {
        return this.focused || !Ember.isEmpty(this.value) || !Ember.isEmpty(this.searchText);
      }
    }).readOnly(),
    actions: {
      search: function search(value) {
        return this.onSearch ? this.onSearch(value) : [];
      },
      onSearchInput: function onSearchInput(value) {
        Ember.set(this, 'searchText', value);

        if (this.onSearchTextChange) {
          this.onSearchTextChange(value);
        }
      },
      onSelect: function onSelect(value) {
        if (this.onChange) {
          this.onChange(value);
        }
      }
    }
  });

  _exports.default = _default;
});