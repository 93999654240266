define("@dwind/widi-uikit/components/widi-form", ["exports", "@dwind/widi-uikit/components/widi-parent-component"], function (_exports, _widiParentComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //import { computed } from '@ember/object';
  //import { isEmpty } from '@ember/utils';
  var _default = _widiParentComponent.default.extend({
    tagName: 'form',
    componentName: 'widi-form',
    classNameBindings: ['isValid:widi-form-valid:widi-form-invalid'],
    attributeBindings: ['action', 'method'],
    autocompleteComponent: 'widi-autocomplete',
    buttonComponent: 'widi-button',
    checkboxComponent: 'widi-checkbox',
    inputComponent: 'widi-input',
    radioComponent: 'widi-radio',
    radioGroupComponent: 'widi-radio-group',
    submitComponent: 'widi-submit',
    isValid: true,

    /*isValid: computed('childComponents.@each.{enabled,required,value}', {
      get() {
        return !this.childComponents
          .filter(({ enabled, required }) => { return enabled && required; })
          .any(({ value }) => { return isEmpty(value); });
      }
    }).readOnly(),*/
    submit: function submit(event) {
      if (this.onSubmit) {
        event.preventDefault();

        if (this.isValid) {
          var values = this.childComponents.reduce(function (values, _ref) {
            var name = _ref.name,
                value = _ref.value;
            values[name] = value;
            return values;
          }, {});
          this.onSubmit(values);
        }
      }
    }
  });

  _exports.default = _default;
});