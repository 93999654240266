define("@dwind/widi-uikit/templates/components/widi-countdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "u1Dd0Qgn",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[27,\"hash\",null,[[\"days\",\"hours\",\"minutes\",\"seconds\"],[[23,[\"days\"]],[23,[\"hours\"]],[23,[\"minutes\"]],[23,[\"seconds\"]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@dwind/widi-uikit/templates/components/widi-countdown.hbs"
    }
  });

  _exports.default = _default;
});