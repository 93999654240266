define("@dwind/widi-uikit/templates/components/widi-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QdDk5NMk",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,1]],null,{\"statements\":[[0,\"  \"],[14,1,[[27,\"hash\",null,[[\"label\"],[[27,\"component\",[[23,[\"labelComponent\"]]],[[\"for\"],[[23,[\"componentId\"]]]]]]]]]],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"input\"],[12,\"id\",[21,\"componentId\"]],[12,\"checked\",[21,\"checked\"]],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"onClick\"],null]],[11,\"type\",\"checkbox\"],[9],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@dwind/widi-uikit/templates/components/widi-checkbox.hbs"
    }
  });

  _exports.default = _default;
});