define("@dwind/widi-uikit/components/widi-parent-component", ["exports", "@dwind/widi-uikit/components/widi-base-component"], function (_exports, _widiBaseComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _widiBaseComponent.default.extend({
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'childComponents', []);
    },
    register: function register(child) {
      var _this = this;

      this.childComponents.push(child);
      Ember.run.scheduleOnce('afterRender', this, function () {
        return _this.notifyPropertyChange('childComponents');
      });
    },
    unregister: function unregister(child) {
      var _this2 = this;

      this.childComponents.removeObject(child);
      Ember.run.scheduleOnce('afterRender', this, function () {
        return _this2.notifyPropertyChange('childComponents');
      });
    }
  });

  _exports.default = _default;
});