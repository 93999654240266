define("@dwind/widi-uikit/components/widi-select", ["exports", "@dwind/widi-uikit/components/widi-parent-component"], function (_exports, _widiParentComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _widiParentComponent.default.extend({
    tagName: 'select',
    componentName: 'widi-select',
    optionComponent: 'widi-select-option',
    change: function change(_ref) {
      var value = _ref.target.value;

      if (this.onChange) {
        this.onChange(value);
      }
    }
  });

  _exports.default = _default;
});