define("@dwind/widi-uikit/components/widi-label", ["exports", "@dwind/widi-uikit/components/widi-child-component"], function (_exports, _widiChildComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _widiChildComponent.default.extend({
    componentName: 'widi-label',
    tagName: 'label',
    attributeBindings: ['for']
  });

  _exports.default = _default;
});