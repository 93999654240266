define("@dwind/widi-uikit/components/widi-modal", ["exports", "@dwind/widi-uikit/components/widi-base-component"], function (_exports, _widiBaseComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _widiBaseComponent.default.extend({
    componentName: 'widi-modal',
    classNameBindings: ['open:widi-modal-open'],
    contentComponent: 'widi-modal-content',
    footerComponent: 'widi-modal-footer',
    headerComponent: 'widi-modal-header',
    click: function click(event) {
      if (event.target.classList.contains('widi-modal')) {
        this.actions.closeModal.call(this);
      }
    },
    actions: {
      closeModal: function closeModal() {
        Ember.set(this, 'open', false);

        if (this.onClose) {
          this.onClose();
        }
      }
    }
  });

  _exports.default = _default;
});