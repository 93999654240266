define("@dwind/widi-uikit/components/widi-select-option", ["exports", "@dwind/widi-uikit/components/widi-child-component"], function (_exports, _widiChildComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _widiChildComponent.default.extend({
    tagName: 'option',
    componentName: 'widi-select-option',
    attributeBindings: 'value',
    selected: Ember.computed('parentComponent.value', 'value', {
      get: function get() {
        return this.value === Ember.get(this, 'parentComponent.value');
      }
    })
  });

  _exports.default = _default;
});