define("@dwind/widi-uikit/templates/components/widi-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iSCIi8ch",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"widi-card__header\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"title\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"widi-card__title\"],[12,\"style\",[27,\"if\",[[23,[\"background\"]],[27,\"concat\",[\"background-image:url('\",[23,[\"background\"]],\"')\"],null],\"\"],null]],[9],[0,\"\\n      \"],[1,[21,\"title\"],true],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"widi-card__content\"],[9],[0,\"\\n  \"],[14,1],[0,\"\\n\"],[4,\"if\",[[23,[\"redirectTo\"]]],null,{\"statements\":[[0,\"    \"],[7,\"a\"],[12,\"href\",[21,\"redirectTo\"]],[11,\"class\",\"widi-card__action\"],[9],[1,[27,\"t\",[\"widi-uikit.card.discover\"],null],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@dwind/widi-uikit/templates/components/widi-card.hbs"
    }
  });

  _exports.default = _default;
});