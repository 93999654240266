define("@dwind/widi-uikit/components/widi-tabs-tab", ["exports", "@dwind/widi-uikit/components/widi-child-component"], function (_exports, _widiChildComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _widiChildComponent.default.extend({
    active: false
  });

  _exports.default = _default;
});