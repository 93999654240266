define("@dwind/widi-uikit/templates/components/widi-radio", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6dKEaQWe",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"input\"],[12,\"id\",[21,\"componentId\"]],[12,\"value\",[21,\"value\"]],[12,\"name\",[21,\"name\"]],[12,\"checked\",[27,\"if\",[[23,[\"checked\"]],\"checked\"],null]],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"onClick\"],null]],[11,\"type\",\"radio\"],[9],[10],[0,\"\\n\"],[4,\"if\",[[24,1]],null,{\"statements\":[[0,\"  \"],[7,\"label\"],[12,\"for\",[21,\"componentId\"]],[9],[14,1],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"label\"],[12,\"for\",[21,\"componentId\"]],[9],[1,[21,\"label\"],false],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@dwind/widi-uikit/templates/components/widi-radio.hbs"
    }
  });

  _exports.default = _default;
});